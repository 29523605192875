import L from 'leaflet'

const travelModeColors = {
  other: '#888888',
  driving: '#DF2935',
  taxi: '#FAA916',
  hitchHiking: '#DF2935',
  walking: '#3772FF',
  hiking: '#3772FF',
  bicycling: '#44AF69',
  bus: '#97DB4F',
  train: '#79C99E',
  airplane: '#087E8B',

  sports: '#A020F0',
}

up.compiler('[map]', (element, data) => {
  console.log('maasp')
  const map = L.map(element).setView([51.367255, 10.8970229], 5)

  L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
    attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
  }).addTo(map)

  const style = (feature) => {
    return {
      color: feature.properties.color || travelModeColors['other'],
      weight: 3,
      opacity: feature.properties.opacity || 1,
    }
  }
  const linesLayer = L.geoJSON(null, { style }).addTo(map)

  let sections = []
  let locations = []
  if (data?.trip) {
    sections = data.trip.sections
    locations = data.trip.locations
  } else if (data?.trips) {
    // console.log('tripsd')
  }

  if (sections) {
    for (const section of sections) {
      const color = travelModeColors[section.travel_mode]
      const line = {
        type: 'LineString',
        coordinates: section.coordinates.map(({ latitude, longitude }) => ([longitude, latitude])),
      }

      linesLayer.addData({
        type: 'Feature',
        geometry: line,
        properties: {
          color: color,
          opacity: section.travel_mode === 'airplane' ? 0.3 : 1
        },
      })
    }
  }
  // console.log('locaitonsd', data)

  if (locations) {
    for (const location of locations) {
      const marker = L.circleMarker([location.coordinate.latitude, location.coordinate.longitude], {
        radius: 5,
        color: '#000',
        // opacity: 0.75,
      }).addTo(map)
      marker.on('click', () => {
        up.layer.open({ url: `/locations/${location.id}?trip_id=${data.trip.id}`, history: false, size: 'large' })
        // up.layer.open({ url: `/locations/${location.id}?trip_id=${data.trip.id}`, size: 'large' })
      })
    }
  }
})