import './stylesheets/application.sass'
import './stylesheets/blocks/**/*.sass'

// import '@hotwired/turbo-rails'
import '@fortawesome/fontawesome-free/css/all.css'

import 'unpoly/unpoly.js'
import 'unpoly/unpoly.css'

import 'leaflet'
import 'leaflet/dist/leaflet.css'
import "leaflet/dist/images/**/*.png"

import './javascripts/**/*.js'

// Turbo.setFormMode('optin')